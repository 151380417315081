import React from 'react'
import { useAuth } from 'src/utils/auth'
import getImageUrl from 'src/utils/getImageUrl'

export default function SidebarProfile() {
  const { user }: any = useAuth()
  const userCoverImage =
    user?.coverImage?.length > 0
      ? getImageUrl(user?.coverImage && user?.coverImage[0]?.url)
      : 'https://images.unsplash.com/photo-1622557850710-d08a111d3476?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80'

  return (
    <div className="feed-sidebar-profile-alignment">
      <div className="feed-sidebar-profile-details">
        <div className="feed-sidebar-profile-img">
          <img
            src={user?.logo_image ? getImageUrl(user?.logo_image) : '/assets/img/dummy-profile.svg'}
            alt={user?.profileImage?.alternativeText}
          />
        </div>
        <div className="feed-sidebar-profile-name">
          <h6>
            {user?.firstName ? user?.firstName : 'Unnamed'} {" "} {user?.lastName}
          </h6>
          {/* <div className="profile-status-alignment">
            <p> </p>
            <span>Online</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="4" viewBox="0 0 7 4" fill="none">
              <path d="M0.5 0.5L3.5 3.5L6.5 0.5" stroke="#7A7E88" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div> */}
        </div>
      </div>
    </div>
  )
}
