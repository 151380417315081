import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FOLLOW_STORE from 'src/graphql/mutations/followStore'
import UNFOLLOW_STORE from 'src/graphql/mutations/unFollowStore'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useAuth } from 'src/utils/auth'
import { notification } from 'antd'
import LazyImage from 'src/utils/LazyImage'
import Link from 'next/link'
import { fetchPopularStoreData } from 'src/store/slices/fetchAllStoresSlice'

export default function NewSuggestedStores() {
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(2)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const allPopularStores = useSelector((state: any) => state?.storesData?.allPopularStores)
  // const { loading, error, data, refetch } = useQuery(GET_ALL_STORE, {
  //   variables: {
  //     limit: 2,
  //     page: 1,
  //     type: "ALL_STORE"
  //   },
  // })

  // useEffect(() => {
  //   refetch()
  // }, [])
  useEffect(() => {
    dispatch(fetchPopularStoreData({ limit: limit, page: page, type: 'ALL_STORE', search: '' }))
  }, [])

  const handleOpenStoreModal = (store: any) => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          {
            modal: 'store',
            isOpen: true,
            id: {
              store: {
                name: store?.name,
                id: store?.id,
                slug: store?.slug,
              },
            },
            modalFor: 'bigModal',
            startDate: moment().toISOString(),
          },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  const [followStore] = useMutation(FOLLOW_STORE, {
    onCompleted: (res) => {
      dispatch(fetchPopularStoreData({ limit: limit, page: page, type: 'ALL_STORE', search: '' }))
    },
    onError: (err) => {},
  })

  const [unfollowStore] = useMutation(UNFOLLOW_STORE, {
    onCompleted: (res) => {
      setTimeout(() => {
        dispatch(fetchPopularStoreData({ limit: limit, page: page, type: 'ALL_STORE', search: '' }))
      }, 500)
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const handleFollow = (st) => {
    if (user) {
      followStore({
        variables: { store_id: st.id },
        // update: (proxy, { data }) => {
        //   const joinedGroup: any = proxy.readQuery({
        //     query: GET_ALL_STORE,
        //     variables: {
        //       limit: limit,
        //       page: page,
        //       type: "JOINED",
        //     },
        //   })
        //   dispatch(fetchPopularStoreData({ limit: limit, page: page, type: "ALL_STORE" }))
        //   let updatedData = joinedGroup?.getAllGroups?.map((grp) => {
        //     if (grp?.id === st?.id) {
        //       return {
        //         ...grp,
        //         isFollow: true,
        //       }
        //     } else {
        //       return grp
        //     }
        //   })
        //   proxy.writeQuery({
        //     query: GET_ALL_STORE,
        //     variables: {
        //       limit: limit,
        //       page: page,
        //       type: "JOINED",
        //     },
        //     data: { getAllGroups: updatedData },
        //   })
        // },
        // refetchQueries: [
        //   {
        //     query: GET_ALL_STORE,
        //     variables: {
        //       limit: limit,
        //       page: page,
        //       type: "ALL_STORE"
        //     },
        //   },
        // ],
      })
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  return (
    <div>
      {/* {data?.getAllStore?.data?.stores?.length > 0 ? ( */}
      <div className="feed-new-suggested-details-alignment">
        {allPopularStores && (
          <div className="feed-new-suggested-box-alignment">
            <div className="feed-new-suggested-box-heading">
              <h6>Suggested Stores</h6>
              <a
                onClick={() => {
                  dispatch(
                    fetchModalData({
                      allModalData: [
                        ...getAllState?.allModalData,
                        { modal: 'storesModal', isOpen: true, id: 0, modalFor: 'mediumModal' },
                      ],
                      lastModalData: getAllState?.lastModalData,
                    }),
                    // dispatch(searchPostImage({name:getAllState}))
                  )
                }}
              >
                See More
              </a>
            </div>

            {allPopularStores?.map(
              (store, i) =>
                i < 2 && (
                  <div className="feed-new-suggested-body-details-alignment" key={i}>
                    <div className="feed-new-suggested-child-box-alignment">
                      <div className="feed-new-suggested-img-alignment">
                        {/* <img src={store?.logo} alt="demo img" /> */}
                        <LazyImage
                          image={{ src: store?.logo_image, alt: 'Icon' }}
                          className={'new-suggested-img-alignment-img'}
                          objectFit={'contain'}
                        />
                      </div>
                      <div className="feed-new-suggested-profile-details">
                        <Link href={`/store/${store?.slug}`}>
                          <h6
                          // onClick={() => handleOpenStoreModal(store)}
                          >
                            {store?.name}
                          </h6>
                        </Link>

                        <div className="feed-new-suggested-flex-alignment">
                          <span>{store?.followers_count || 0} followers</span>
                          <p></p>
                          <span>{store?.product_count || 0} products</span>
                        </div>
                        {store?.isFollow ? (
                          <div className="feed-new-suggested-unfollow-details">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="10"
                                viewBox="0 0 13 10"
                                fill="none"
                              >
                                <path
                                  d="M1 4.5L5 8.5L12 1"
                                  stroke="#7A7E88"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <a>Followed</a>
                          </div>
                        ) : (
                          <div className="feed-new-suggested-follow-details" onClick={() => handleFollow(store)}>
                            <a>+ Follow Store</a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ),
            )}
          </div>
        )}
      </div>
      {/* ) : (
        <></>
      ) */}
      {/* } */}
    </div>
  )
}
