import React, { useEffect, useRef, useState } from 'react'
import PostSomething from 'src/components/Feed/PostSomething'
import { Post } from 'src/types'
import NormalPost from '../NormalPost'
import PostSomethingMobile from '../PostSomethingMobile'
import NewLeftSidebar from 'src/components/shared/NewLeftSidebar'
import NewSuggestedClubs from '../NewSuggestedClubs'
import NewSuggestedStores from '../NewSuggestedStores'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchAllFeedSliceData, fetchProductRecommendation } from 'src/store/slices/fetchAllFeedSlice'
import SendInvitation from '../SendInvitation'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import HeaderWelcomeline from 'src/components/shared/Common/HeaderWelcomline/HeaderWelcomeline'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useAuth } from 'src/utils/auth'
import Slider from 'react-slick'
import Recommendations from '../../ProductPage/About/Recommendations/Recommendations'
import { useMutation } from '@apollo/client'
import DELETE_LIKE_MUTATION from 'src/graphql/mutations/deleteLike'
import CREATE_LIKE_MUTATION from 'src/graphql/mutations/createLike'
import ProductRecommendation from '../ProductRecommendation'

const clientId = process.env.NEXT_PUBLIC_CLIENT_ID

export default function FeedComponent(props: any) {
  const containerRef = useRef(null)
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [openDropdown, setOpenDropdown] = useState<any>(false)
  const [recommendationIndex, setRecommendationIndex] = useState(0)
  const [productRecommendationsFetched, setProductRecommendationsFetched] = useState(false)
  const [recommendationsMap, setRecommendationsMap] = useState<Record<number, any>>({})

  const allPost = useSelector((state: any) => state?.feedData?.allPost)
  const productRecommendations = useSelector((state: any) => state?.feedData?.productRecommendations)

  const isallPostEnd = useSelector((state: any) => state?.feedData?.isEnd)
  const {
    product,
    storeData,
    isInCollection,
    isShowToast,
    isForPost,
    isRemoveProduct,
    className,
    dataLimite,
    APIcall,
  } = props
  const { user } = useAuth()

  useEffect(() => {
    dispatch(fetchAllFeedSliceData({ page, limit, prevPost: page === 1 ? [] : allPost }))
  }, [])

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  useEffect(() => {
    const container = containerRef.current

    const handleScroll = () => {
      if (container.scrollHeight - container.scrollTop - container.clientHeight <= 2000 && !isallPostEnd) {
        setPage(page + 1)
        dispatch(fetchAllFeedSliceData({ page: page + 1, limit, prevPost: allPost })).then(() => {
          if (user && allPost.length % 5 === 0 && allPost.length !== 0) {
            const recommendationKey = Math.floor(allPost.length / 5)
            if (!recommendationsMap[recommendationKey]) {
              dispatch(fetchProductRecommendation({ type: 'shopping_trend', prevRecommendation: [] })).then(
                (response: any) => {
                  setRecommendationsMap((prev) => ({
                    ...prev,
                    [recommendationKey]: response.payload, // Store unique data for each section
                  }))
                },
              )
            }
          }
        })
      }
      if (isallPostEnd && container.scrollHeight === container.scrollTop + container.clientHeight) {
        handleRedirect()
      }
    }
    if (container) {
      container.addEventListener('scroll', handleScroll)
      return () => container.removeEventListener('scroll', handleScroll)
    }
  }, [containerRef, user, allPost, recommendationsMap])

  // useEffect(() => {
  //   if (user && allPost.length % 5 === 0 && allPost.length !== 0) {
  //     dispatch(fetchProductRecommendation('shopping_trend')).then((action) => {
  //       if (action.payload && action.payload.length > 0) {
  //         setProductRecommendations(action.payload)
  //       }
  //     })
  //   }
  // }, [allPost, user])

  function handleClose() {
    setOpenDropdown(false)
  }

  const [createLike] = useMutation(CREATE_LIKE_MUTATION, {
    onCompleted: async (response, { context: { product_id } }) => {
      if (response?.createLike?.id) {
        APIcall(product_id, 'like')
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [deleteLike] = useMutation(DELETE_LIKE_MUTATION, {
    onCompleted: async (response, { context: { product_id } }) => {

      if (response?.disLike?.user?.id) {
        APIcall(product_id, 'dislike')
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const likeProduct = (product) => {
    if (user) {
      if (product?.likes?.id !== 3.3333) {
        const likeFor = 'PRODUCT'
        if (product?.likes?.id) {
          deleteLike({
            variables: { id: product?.likes?.id, like_for: likeFor },
            context: { product_id: product?.id },
          })
          return
        } else {
          createLike({
            variables: { parent_id: product?.id, like_for: likeFor },
            context: { product_id: product?.id },
          })
        }
      }
    } else {
      handleRedirect()
    }
  }

  return (
    <>
      <div className="lg:bg-white feed-page-alignment" ref={containerRef}>
        <div className="mx-auto mobile:hidden tablet:hidden smallTablet:hidden" style={{ maxWidth: 1300 }}>
          <div className="feed-page-layout-alignment">
            <div className="left-sidebar-alignment">
              <div className="relative w-full h-100">
                <div className="sticky top-12">
                  <NewLeftSidebar setIsModalVisible={setIsModalVisible} />
                </div>
              </div>
            </div>
            <div className="center-feed-details">
              <PostSomething title={'Post Something'} mobile={false} type="post" group={{}} isSeller={false} />
              {allPost?.map((post: Post, i) => {
                return (
                  <div key={i}>
                    <NormalPost
                      index={i}
                      post={post}
                      store={{}}
                      image={true}
                      type="post"
                      color={false}
                      feedSize={true}
                      isUserPostPreviewModal={true}
                    />
                    {(i + 1) % 5 === 4 && recommendationsMap[Math.floor(i / 5)] && (
                      <ProductRecommendation productRecommendations={recommendationsMap[Math.floor(i / 5)]} />
                    )}
                  </div>
                )
              })}
            </div>
            <div className="right-sidebar-alignment">
              <div className="relative h-100">
                <div className="sticky top-12">
                  <div className="right-side-overflow-alignment">
                    <NewSuggestedStores />
                    <NewSuggestedClubs />
                    <HeaderWelcomeline />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ============================================== MOBILE_COMPONENTS ============================================== */}
        <PostSomethingMobile title={'Create Post'} mobile={true} type="post" group={{}} isSeller={false} />
        <div className="mobile:block tablet:block smallTablet:block lg:hidden mobile-view-height-alignment">
          {allPost?.length > 0 ? (
            allPost?.map((post: any, i) => {
              const key = Math.floor(Math.random() * 900) + 100
              return (
                <div key={i}>
                  <NormalPost
                    index={i}
                    post={post}
                    store={{}}
                    image={true}
                    type="post"
                    color={false}
                    feedSize={true}
                    isUserPostPreviewModal={false}
                  />
                  {(i + 1) % 5 === 4 && recommendationsMap[Math.floor(i / 5)] && (
                    <ProductRecommendation productRecommendations={recommendationsMap[Math.floor(i / 5)]} />
                  )}{' '}
                </div>
              )
            })
          ) : (
            <>
              <div className="no-data-box-alignment"></div>
            </>
          )}
        </div>
      </div>
      {isModalVisible && (
        <GoogleOAuthProvider clientId={clientId}>
          <SendInvitation setIsModalVisible={setIsModalVisible} />
        </GoogleOAuthProvider>
      )}
    </>
  )
}
