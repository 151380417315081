import { useEffect, useRef } from 'react'
import styles from './HeaderWelcomeline.module.scss'

export default function HeaderWelcomeline() {
  const marqueeRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    if (marqueeRef?.current && containerRef?.current) {
      const marqueeWidth = marqueeRef?.current?.offsetWidth
      containerRef?.current?.style?.setProperty('--marquee-width', `${marqueeWidth}px`)

      const animationDuration = 60
      const pauseDuration = 5

      const animate = () => {
        containerRef?.current?.classList?.remove(styles.paused)
        containerRef?.current?.classList?.add(styles.animated)

        setTimeout(() => {
          containerRef?.current?.classList?.remove(styles.animated)
          containerRef?.current?.classList?.add(styles.paused)

          setTimeout(animate, pauseDuration * 1000)
        }, animationDuration * 1000)
      }

      animate()
    }
  }, [])

  return (
    <div className={styles.headerWelcomeline}>
      <div className={styles.marqueeContainer} ref={containerRef}>
        <div className={styles.marquee} ref={marqueeRef}>
          <p>
            Welcome to Bluejestic Beta v1.0! 🚀 We're excited to have you on this journey with us. If you encounter any
            bugs or issues, please{' '}
            <a href="https://formbricks.internal.bluejestic.com/s/cm2e88bmt002nzu796xr0hjrd" target="_blank">
              click here
            </a>{' '}
            to report them. Your feedback is key to helping us build one of the best social shopping platforms out
            there! 🙌
          </p>
        </div>
      </div>
    </div>
  )
}
