import React, { useEffect, useState } from 'react'
import styles from './SendinvitationEmail.module.scss'
import { useMutation } from '@apollo/client'
import SEND_INVITATION from 'src/graphql/mutations/sendInvitation'
import { useDispatch } from 'react-redux'

export default function SendInvitationEmail({ isOpen, onClose, transformedContacts, setIsModalVisible }) {
  const dispatch = useDispatch()
  const [isChecked, setIsChecked] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedEmails, setSelectedEmails] = useState([])

  useEffect(() => {
    if (transformedContacts.length > 0) {
      setIsChecked(Array(transformedContacts.length).fill(false))
    }
  }, [transformedContacts])

  const handleCheckboxToggle = (index) => {
    const updatedChecked = [...isChecked]
    updatedChecked[index] = !updatedChecked[index]
    setIsChecked(updatedChecked)

    const email = transformedContacts[index]?.emailAddresses?.[0]?.value
    if (email) {
      setSelectedEmails((prev) => {
        if (updatedChecked[index]) {
          return prev.includes(email) ? prev : [...prev, email] // Add only if not already included
        } else {
          return prev.filter((e) => e !== email) // Remove if unchecked
        }
      })
    }
  }

  const handleSelectAllToggle = () => {
    const allChecked = isChecked.every((checked) => checked)
    const newCheckedState = Array(transformedContacts.length).fill(!allChecked)
    setIsChecked(newCheckedState)

    if (!allChecked) {
      const allEmails = transformedContacts
        .filter((item) => item?.emailAddresses?.[0]?.value)
        .map((item) => item.emailAddresses[0].value)
      setSelectedEmails(allEmails)
    } else {
      setSelectedEmails([]) // Clear selection on "Uncheck All"
    }
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase())
  }

  const [sendInvitation] = useMutation(SEND_INVITATION, {
    onCompleted: ({ sendInvitation }) => {
      onClose()
      setIsModalVisible(false)
    },
    onError: (error) => {
    },
  })

  const handleSendInvitation = () => {
    sendInvitation({
      variables: {
        emails: selectedEmails,
      },
    })
  }

  // Filtered contacts based on search query
  const filteredContacts = transformedContacts.filter((item) => {
    const email = item?.emailAddresses?.[0]?.value || ''
    const phone = item?.phoneNumbers?.[0]?.canonicalForm || ''
    return email.toLowerCase().includes(searchQuery) || phone.includes(searchQuery)
  })

  if (!isOpen) return null

  return (
    <div className={styles.SendInvitationEmailmain}>
      <div className={styles.sendInvitationEmailModalBox}>
        <div className={styles.SendinvitationEmailModalHeding}>
          <h4>Invite Friends to Bluejestic</h4>
          <div className={styles.SendininvitationEmailheadingCLose} onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M18 6L6 18" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M6 6L18 18" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
        <div className={styles.SendinvitationEmailModelbody}>
          <div className={styles.SendinvitationEmailModeltitle}>
            <p>Invite friends using your Gmail address book</p>
            <div className={styles.SendInvitationEmailmodelSearchbar}>
              <input
                type="search"
                placeholder="Search"
                value={searchQuery} // Bind input to searchQuery state
                onChange={handleSearchChange}
              />
              <div className={styles.SendInvitationEmailmodelSearcicon}>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19 27C23.4183 27 27 23.4183 27 19C27 14.5817 23.4183 11 19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27Z"
                    stroke="#ACB1C0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M28.9999 29L24.6499 24.65"
                    stroke="#ACB1C0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className={styles.SendinvitationEmailModelSelectedfriendnumbers}>
            <p>{isChecked.filter((checked) => checked).length} friends selected</p>
          </div>
          <div className={styles.SendinvitationEmailModelSelectfriend}>
            <p>Check all / Uncheck all</p>
            <div
              className={`${styles.SendinvitationEmailModelSelectallfriendcheckbox} ${
                isChecked.every((checked) => checked) ? styles.SendinvitationEmailModelSelectallfriendcheckboxcheck : ''
              }`}
              onClick={handleSelectAllToggle}
            >
              <div className={styles.SendinvitationEmailModelSelectallfriendcheckboxcheckedcircle}></div>
            </div>
          </div>
          <div className={styles.SendinvitationEmailModelSelectedfriendsmainbody}>
            {filteredContacts?.map((item, index) => {
              const email = item?.emailAddresses?.[0]?.value
              return (
                <div>
                  {item?.emailAddresses?.length > 0 && (
                    <div
                      className={styles.SendinvitationEmailModelSelectedfriendsboxm}
                      onClick={() => handleCheckboxToggle(index)}
                    >
                      <span>{email}</span>
                      <div
                        className={`${styles.SendinvitationEmailModelSelectallfriendcheckbox} ${
                          isChecked[index] ? styles.SendinvitationEmailModelSelectallfriendcheckboxcheck : ''
                        }`}
                        onClick={() => handleCheckboxToggle(index)}
                      >
                        <div className={styles.SendinvitationEmailModelSelectallfriendcheckboxcheckedcircle}></div>
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
          <div className={styles.SendininvitationEmailLastbuttonsmain}>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
            <button
              type="button"
              disabled={!isChecked.some((checked) => checked)}
              onClick={() => handleSendInvitation()}
            >
              Send Invitations
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
