import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from 'src/utils/auth'
import { useMutation } from '@apollo/client'
import BlackheartIcon from '../../../../../public/assets/icon/heart-black.svg'
import ShareIcon from '../../../../../public/assets/icon/share-black.svg'
import TextMessageIcon from '../../../../../public/assets/icon/text-message.svg'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import BlueShoppingIcon from '../../../../../public/assets/icon/blue-shopping-bag.svg'
import nookies from 'nookies'
import star from '../../../../../public/assets/img/WomansCategory/star.png'
import aroww from '../../../../../public/assets/img/WomansCategory/aroww.png'
import WhiteMoreArrow from '../../../../../public/assets/icon/white-more-icon.svg'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import moment from 'moment'
import { fetchProductShareOptions } from 'src/store/slices/productShareOptionsSlice'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import ADD_BOOKMARK from 'src/graphql/mutations/addBookmark'
import REMOVE_BOOKMARK from 'src/graphql/mutations/removeBookmark'
import GET_ALL_BOOKMARK from 'src/graphql/queries/getAllBookmark'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import LazyImage from 'src/utils/LazyImage'
import LazyProductImage from 'src/utils/LazyProductImage'
import { useRouter } from 'next/router'
import { manageBookmark } from 'src/store/slices/manageBookmarkTab'
import REMOVE_PRODUCT_FROM_COLLECTIONS from 'src/graphql/mutations/removeProductFromCollection'
import {
  fetchAllBookmarkProducts,
  fetchAllStoreProducts,
  setStoreProductState,
} from 'src/store/slices/fetchAllStoresSlice'
import { fetchProductByCategory, setProductByCategoryState } from 'src/store/slices/fetchAllProduct'
import CREATE_LIKE_MUTATION from 'src/graphql/mutations/createLike'
import DELETE_LIKE_MUTATION from 'src/graphql/mutations/deleteLike'
import CREATE_CART_MUTATION from 'src/graphql/mutations/createCart'
import { getAllCartCountSliceData } from 'src/store/slices/getCartCount'
import { notification } from 'antd'


export default function Productv2(props: any) {
  const { user } = useAuth()
  const router = useRouter()
  const {
    product,
    storeData,
    isInCollection,
    isShowToast,
    isForPost,
    isRemoveProduct,
    className,
    dataLimite,
    page,
    setPage,
    bookMark,
    APIcall,
  } = props
  const allStores = useSelector((state: any) => state?.storesData)

  const productRef: any = useRef()
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const bookmarkTabData = useSelector((state: any) => state?.manageBookmarkTabsSlice?.data)
  const [indexImage, setIndexImage] = useState(0)
  const [selectedProduct, setSelectedProduct] = useState<any>({})
  const [openDropdown, setOpenDropdown] = useState<any>(false)
  const [isCalling, setIsCalling] = useState(false)
  const [isDesktop, setIsDesktop] = useState(0)
  const [isLiked, setIsLiked] = useState(false)
  const [isBookmark, setIsBookmark] = useState(false)
  // const [productRecommendationsFetched, setProductRecommendationsFetched] = useState(false)

  const handleResize = () => setIsDesktop(window.innerWidth)

  useEffect(() => {
    setIsDesktop(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const liked = localStorage.getItem(`liked_${product?.id}`);
    const bookmarked = localStorage.getItem(`bookmarked_${product?.id}`);
    if (liked) setIsLiked(JSON.parse(liked));
    if (bookmarked) setIsBookmark(JSON.parse(bookmarked));
  }, [product?.id]);

  // useEffect(() => {
  //   const isRefresh = !performance
  //     .getEntriesByType('navigation')
  //     .some((entry) => (entry as PerformanceNavigationTiming).type === 'navigate')

  //   if (isRefresh) {
  //     router.replace(
  //       {
  //         pathname: router.pathname,
  //         query: {},
  //       },
  //       undefined,
  //       { shallow: true },
  //     )
  //     window.scrollTo(0, 0)
  //   } else {

  //     const productId = localStorage.getItem('productId')
  //     const page = localStorage.getItem('page')
  //     if (page) {
  //       // setPage(Number(page))
  //       if (productId) {
  //         const element = document.getElementById(productId)
  //         if (element) {
  //           element.scrollIntoView({ behavior: 'smooth' })
  //           localStorage.removeItem('productId')
  //           localStorage.removeItem('page')
  //         }
  //       }
  //     }
  //     // const cookies = nookies.get(undefined)
  //     // const productId = cookies.productId
  //     // const { page: nookiesPage } = nookies.get()
  //     // if (nookiesPage) {
  //     //   // setPage(Number(nookiesPage))
  //     //   if (productId) {
  //     //     const element = document.getElementById(productId)
  //     //     if (element) {
  //     //       element.scrollIntoView({ behavior: 'smooth' })
  //     //       nookies.destroy(undefined, 'productId', { path: '/' })
  //     //       nookies.destroy(undefined, 'page', { path: '/' })
  //     //     }
  //     //   }
  //     // }
  //   }

  //   const handleBeforeUnload = () => {
  //     nookies.destroy(undefined, 'productId', { path: '/' })
  //   }
  //   window.addEventListener('beforeunload', handleBeforeUnload)

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload)
  //   }
  // }, [router])

  const arrow = (
    <svg width="28" height="28" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="18" cy="17" rx="18" ry="17" fill="black" fillOpacity="0.4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0336 11.0395L15.0677 17.0735L21.1017 23.0393L19.113 25.0506L11.0678 17.0962L19.0222 9.0509L21.0336 11.0395Z"
        fill="#E4E4E8"
      />
    </svg>
  )

  const stopMovie = (e) => {
    e.target.pause()
  }

  const playMovie = (e) => {
    e.target.play()
  }

  const changeIndexImage = (temp: number) => {
    var index = indexImage + temp
    if (index >= product?.images?.length) index = 0
    else if (index < 0) index = product?.images?.length - 1
    setIndexImage(index)
  }

  const handleSaveData = (prod) => {
    if (user) {
      setSelectedProduct(prod)
      setOpenDropdown(true)
    } else {
      handleRedirect()
    }
  }

  const handleOpenProduct = (product) => {
    if (product?.slug) {
      nookies.set(undefined, 'productId', product.id, {
        path: '/',
      })
      nookies.set(undefined, 'page', page, {
        path: '/',
      })
      router.push(`/product/${product?.slug}`)
    }
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          selectedCategories: [],
          selectedColorArray: [],
          selectedColor: '',
          selectedText: '',
          variant: {
            isAddVariantModal: false,
            isAddColorModalOpen: false,
            isAddOtherVariantModalOpen: false,
            dataEdit: {},
            allVariant: [],
            colorCodeArray: [],
            allFinalVariant: [],
            variantError: {},
            isVariantModel: false,
            colorStates: [],
          },
          isEditableData: {
            index: null,
            data: null,
          },
          productPreview: {
            title: '',
            image: '',
            price: '',
            listPrice: '',
          },
          mobileProductsData: [],
          filteredList: [],
          filterColorList: [],
          newVariantDataState: [],
          productDetails: {
            productDetailVariant: {},
            productSelectedOption: {},
            productDetailColor: '',
          },
        },
      }),
    )
  }
  // const [addBookmark] = useMutation(ADD_BOOKMARK, {
  //   onCompleted: (res) => {
  //     dispatch(fetchAllBookmarkProducts({ page: 1, limit: 10 }))
  //     dispatch(
  //       fetchProductByCategory({
  //         category: 'Fashion & Accessories',
  //         subCategory: 'Women',
  //         childSubCategory: router?.query?.category ? router?.query?.category : '',
  //         isShopifyProduct: false,
  //       }),
  //     )
  //   },
  //   onError: (error) => {
  //     console.log(error)
  //   },
  // })

  const [removeBookmark] = useMutation(REMOVE_BOOKMARK, {
    onCompleted: (res) => {
      dispatch(fetchAllBookmarkProducts({ page: 1, limit: 10 }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleAddToCollection = (product) => {
    if (user) {
      dispatch(
        manageBookmark({
          modal: '',
          isOpen: false,
          id: {
            ...bookmarkTabData?.id,
            addProductToCollection: {
              ...bookmarkTabData?.id?.deleteCollection,
              isShowAddCollectionModel: true,
              // collectionId: getAllState?.lastModalData?.item?.id,
              productId: product?.id,
            },
          },
        }),
      )
    } else {
      handleRedirect()
    }
  }

  const [createCart] = useMutation(CREATE_CART_MUTATION, {
    onCompleted: (res) => {
      dispatch(getAllCartCountSliceData())
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Sorry, you can only purchase allowable quantity of this product',
      })
    },
  })

  const QuantityHandler = () => {
    if (user) {
      createCart({
        variables: { input: { parent_id: product?.id, quantity: 1, cart_for: 'PRODUCT' } },
      })
    } else {
      handleRedirect()
    }
  }

  const addBookmarkFromFeed = (product) => {
    if (user && !isBookmark) {
      dispatch(
        manageBookmark({
          modal: '',
          isOpen: true,
          id: {
            ...bookmarkTabData?.id,
            type: 'feed',
            bookmark_id: product?.bookmark?.id,
            product_id: product?.id,
            collection_id: product?.bookmark?.collection?.id,
            addProductToCollection: {
              ...bookmarkTabData?.id?.deleteCollection,
              isShowAddCollectionModel: true,
              productId: product?.id,
            },
          },
        }),
      )
      setIsBookmark(true);
      localStorage.setItem(`bookmarked_${product?.id}`, JSON.stringify(true));
    } else {
      handleRedirect()
    }
  }

  const handleRemoveBookmark = (product) => {
    removeProductFromBookmark({
      variables: {
        collectionId: product?.bookmark?.collection?.id,
        productId: product?.id,
        isRemoveFromCollectionOnly: true,
      },
      refetchQueries: [
        {
          query: GET_ALL_BOOKMARK,
          variables: {
            slug: product?.bookmark?.collection?.id,
            page: 1,
            limit: 10,
          },
        },
      ],
    })
  }

  const [removeProductFromBookmark] = useMutation(REMOVE_PRODUCT_FROM_COLLECTIONS, {
    onCompleted: (res) => { },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  function handleClose() {
    setOpenDropdown(false)
  }

  const handleOpenShareFriend = () => {
    dispatch(fetchProductShareOptions({ modal: 'productShareWithFriend', isOpen: true, id: 0 }))
  }

  const shareFeedOnGroup = () => {
    dispatch(
      fetchProductShareOptions({
        modal: 'productSharewithClub',
        isOpen: true,
        // id: { ...data, groupId: 0, selectedProduct: selectedProduct, isGroupPost: true },
        id: { groupId: 0, selectedProduct: selectedProduct, isGroupPost: true },
      }),
    )
  }

  const shareOnfeed = () => {
    dispatch(
      fetchProductShareOptions({
        modal: 'productShareOnMyFeedModal',
        isOpen: true,
        id: { selectedProduct: selectedProduct, isGroupPost: false },
        // id: { ...data, selectedProduct: selectedProduct, isGroupPost: false },
      }),
    )
  }

  const handelImageSlider = () => { }

  const [createLike] = useMutation(CREATE_LIKE_MUTATION, {
    onCompleted: async (response, { context: { product_id } }) => {

      if (response?.likes?.id) {
        APIcall(product_id, 'like')
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [deleteLike] = useMutation(DELETE_LIKE_MUTATION, {
    onCompleted: async (response, { context: { product_id } }) => {

      if (response?.likes?.id) {
        APIcall(product_id, 'dislike')
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const likeProduct = (product) => {
    if (user && !isLiked) {
      const likeFor = 'PRODUCT';
      createLike({
        variables: { parent_id: product?.id, like_for: likeFor },
        context: { product_id: product?.id },
      });
      setIsLiked(true);
      localStorage.setItem(`liked_${product?.id}`, JSON.stringify(true));
    } else {
      handleRedirect();
    }
  }

  const handleAddBookmark = () => {
    dispatch(
      manageBookmark({
        modal: '',
        isOpen: true,
        id: {
          ...bookmarkTabData?.id,
          // post: post,
          type: 'product',
          bookmark_id: product?.bookmark?.id,
          product_id: product?.id,
          collection_id: product?.bookmark?.collection?.id,
          addProductToCollection: {
            ...bookmarkTabData?.id?.deleteCollection,
            isShowAddCollectionModel: true,
            productId: product?.id,
          },
        },
      }),
    )
  }

  return (
    <div
      id={product?.id}
      className={`product-container-main hide-product-details-show  ${className}`}
      onMouseEnter={handleClose}
    >
      <div className={`image-contain ${isForPost ? 'imageSliderHeightAlignment' : ''}`}>
        {/* imageSliderHeightAlignment */}
        {/* <Link href={`/product/${product?.slug}`}> */}
        <div className="image-contain-dataimage-contain-data" onClick={() => handleOpenProduct(product)}>
          {(product?.images?.length > 0 && product?.images[indexImage]?.src?.includes('mp4')) ||
            (product?.images?.length > 0 && product?.images[indexImage]?.src?.includes('mov')) ? (
            <video loop autoPlay={true} onMouseOver={playMovie} onMouseOut={stopMovie} poster={''}>
              <source src={product?.images?.length > 0 && product?.images[indexImage]?.src} type="video/mp4" />
            </video>
          ) : (
            // <img src={'https://cdn.shopify.com/s/files/1/0842/0704/2859/files/front_8afd0e53-f4ab-40cd-a777-153c6af111a7.jpg?v=1702676692'} />
            // <div
            //   className="poroduct-image-background"
            //   style={{
            //     backgroundImage:
            //       'url(https://cdn.shopify.com/s/files/1/0842/0704/2859/files/front_8afd0e53-f4ab-40cd-a777-153c6af111a7.jpg?v=1702676692)',
            //     // background : "red"
            //   }}
            // ></div>
            <LazyProductImage
              image={{
                src: product?.images?.length > 0 && product?.images[indexImage]?.src,
                alt: 'Icon',
              }}
              objectFit={'cover'}
              // width={308}
              height={isDesktop > 1024 ? 308 : 140}
              handleClickOnImage={() => handelImageSlider()}
              className={'product-main-image-section'}
            />
          )}
          {/* <---------------------------------  mobile view add product bookmark design start ----------------------------------> */}
          {isShowToast &&
            (product?.bookmark?.collection?.name ? (
              <div className="mobile-view-on-summary-section">
                <p>{product?.bookmark?.collection?.name}</p>
              </div>
            ) : (
              <div
                className="mobile-view-add-product-bookmark-section"
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch(
                    fetchMobileModalData({
                      allModalData: [
                        ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                        {
                          modal: 'mobileViewAddProductModal',
                          isOpen: true,
                          id: product?.id,
                          transform: 440,
                        },
                      ],
                      lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
                    }),
                  )
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M3.33301 8L12.6663 8" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M8 3.33301L12.6667 7.99967L8 12.6663"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <g clipPath="url(#clip0_1210_23765)">
                    <path
                      d="M7.38514 2.31989L9.86214 2.0096C10.5461 1.92391 11.1685 2.42159 11.2523 3.1212M3.64466 13.0745C2.96066 13.1602 2.33825 12.6625 2.25447 11.9629L2.02694 10.0628L1.79941 8.16265L1.57188 6.26252L1.34434 4.36239C1.26057 3.66279 1.74715 3.02618 2.43115 2.94049"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="3.9873"
                      y="3.50781"
                      width="10.0298"
                      height="10.0298"
                      rx="0.930002"
                      transform="rotate(3.20951 3.9873 3.50781)"
                      fill="white"
                      stroke="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1210_23765">
                      <rect width="16" height="16" fill="white" transform="translate(0.000976562)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            ))}
          {/* <---------------------------------  mobile view add product bookmark design end ----------------------------------> */}
          <div
            className="mobile-view-more-option-alignment"
            onClick={(e) => {
              e.stopPropagation()
              dispatch(
                fetchMobileModalData({
                  allModalData: [
                    ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                    { modal: 'productOption', isOpen: true, id: product, transform: 508 },
                  ],
                  lastModalData: fetchMobileModal?.lastModalData,
                }),
              )
            }}
          >
            <img src={WhiteMoreArrow.src} alt="WhiteMoreArrow" />
          </div>

          {/* <------------------------------ bookmark product page bookmark icon design start --------------------------------------------> */}
          {/* <div className="mobile-view-bookmark-icon-box-alignment">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div> */}
          {/* <----------------------------------- bookmark product page bookmark icon design end  ----------------------------------------------------> */}

          {/* <div className="mobile-view-off-label-alignment">
            <span>25% off</span>
          </div> */}
        </div>
        {/* </Link> */}
        <div className="onhover-align">
          <div className="image-index-align">
            <div className="number-of-index">
              <span>
                {indexImage + 1}/{product?.images?.length}
              </span>
            </div>
            <div className="like-more-alignment">
              <div className="like-button-alignment" onClick={() => !isLiked && likeProduct(product)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill={isLiked ? '#ff0000' : 'none'}
                >
                  <path
                    d="M9.3877 6.1897C6.61117 6.1897 4.36035 8.44053 4.36035 11.217C4.36035 16.2444 10.3018 20.8147 13.501 21.8778C16.7002 20.8147 22.6416 16.2444 22.6416 11.217C22.6416 8.44053 20.3908 6.1897 17.6143 6.1897C15.914 6.1897 14.4108 7.03379 13.501 8.32577C12.5912 7.03379 11.088 6.1897 9.3877 6.1897Z"
                    stroke="#F8F8F8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="more-button-alignment">
                <div className="option-icon-alignment" onClick={() => handleSaveData(product)}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.5 11C8.32843 11 9 11.6716 9 12.5C9 13.3284 8.32843 14 7.5 14C6.67157 14 6 13.3284 6 12.5C6 11.6716 6.67157 11 7.5 11ZM12.5 11C13.3284 11 14 11.6716 14 12.5C14 13.3284 13.3284 14 12.5 14C11.6716 14 11 13.3284 11 12.5C11 11.6716 11.6716 11 12.5 11ZM19 12.5C19 11.6716 18.3284 11 17.5 11C16.6716 11 16 11.6716 16 12.5C16 13.3284 16.6716 14 17.5 14C18.3284 14 19 13.3284 19 12.5Z"
                      fill="#F8F8F8"
                    />
                  </svg>
                </div>
                {openDropdown && (
                  <div className="more-option-dropdown-alignment" ref={productRef}>
                    <ul>
                      <li>
                        <div
                          className="dropdown-option dropdown-option-first hover:bg-indigo-50 py-3"
                          onClick={() => handleAddBookmark()}
                        >
                          <div className="dropdown-main">
                            <div className="dropdown-icon">
                              <img src="/assets/img/store/product/bookmark.svg" />
                            </div>
                            <div className="dropdown-content">
                              <h5>Add to Bookmark</h5>
                              <p>Save this product for later</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="dropdown-option py-3" onClick={() => shareOnfeed()}>
                          <div className="dropdown-main">
                            <div className="dropdown-icon">
                              <img src="/assets/img/store/product/grid.svg" />
                            </div>
                            <div className="dropdown-content">
                              <h5>Share on my feed</h5>
                              <p>Share this post on my feed</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div
                          className="dropdown-option dropdown-option-last hover:bg-indigo-50 py-3"
                          // onClick={() => setIsShareFriedModalVisible(true)}
                          onClick={() => handleOpenShareFriend()}
                        >
                          <div className="dropdown-main">
                            <div className="dropdown-icon">
                              <img src="/assets/img/store/product/share-with-frd.svg" />
                            </div>
                            <div className="dropdown-content">
                              <h5>Share with Friends</h5>
                              <p>Share post with friends</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div
                          className="dropdown-option dropdown-option-last hover:bg-indigo-50 py-3"
                          onClick={() => shareFeedOnGroup()}
                        >
                          <div className="dropdown-main">
                            <div className="dropdown-icon">
                              <img src="/assets/img/store/product/share-with-club-frd.svg" />
                            </div>
                            <div className="dropdown-content">
                              <h5>Share with Club</h5>
                              <p>Share post with clubs friends</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hover-info-align">
            <button onClick={() => changeIndexImage(-1)}>{arrow}</button>
            <button onClick={() => changeIndexImage(1)} style={{ transform: 'rotate(180deg)' }}>
              {arrow}
            </button>
          </div>
          <div className="bottom-section">
            <div className="product-color-main">
              <div className="color-alignment">
                <img src={'/assets/img/product/product-color-ellipse.png'} />
                <span>3</span>
              </div>
            </div>
            {isShowToast && (
              <div className="add-product-collection-alignment">
                {!product?.bookmark?.collection?.name && (
                  <div className="add-production-box">
                    <div className="collection-icon arrow-left-alignment">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M5 12L19 12"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 5L19 12L12 19"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="collection-icon" onClick={() => handleAddToCollection(product)}>
                      {/* <img src="/assets/icon/collection-icon.svg" alt="collection icon" /> */}
                    </div>
                  </div>
                )}
                {isInCollection && user && (
                  <div className="add-production-box">
                    <div className="collection-icon arrow-left-alignment">
                      <img src="/assets/icon/arrow-right-white.svg" alt="arrow icon" />
                    </div>
                    <div className="collection-icon" onClick={() => handleRemoveBookmark(product)}>
                      <img src="/assets/icon/collection-icon.svg" alt="collection icon" />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {isShowToast && product?.bookmark?.collection?.name && !isInCollection && (
            <div className="mobile-view-on-summary-section">
              <p>{product?.bookmark?.collection?.name}</p>
            </div>
          )}
        </div>
      </div>
      {!isForPost && (
        <div className="grid-image-main-handler">
          <div className="spacer-image-grid-alignment">
            <div className="product-image-list">
              {product?.images?.slice(0, 8)?.map((img, index) => {
                if (img?.src?.includes('mp4') || img?.src?.includes('mov')) {
                  return (
                    <div
                      className={indexImage === index ? 'media-border active-border' : 'media-border'}
                      onClick={() => setIndexImage(index)}
                      key={index}
                    >
                      <video loop>
                        <source src={img?.src} type="video/mp4" />
                      </video>
                    </div>
                  )
                } else {
                  return (
                    <div
                      className={indexImage === index ? 'media-border active-border' : 'media-border'}
                      onClick={() => setIndexImage(index)}
                      key={index}
                    >
                      {/* <img src={img} /> */}
                      <LazyImage
                        image={{ src: img?.src, alt: 'Icon' }}
                        objectFit={'cover'}
                        handleClickOnImage={() => handelImageSlider()}
                        // imageClassName={'post-media-radious'}
                        className={'product-sub-image'}
                      />
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
      )}
      {/* <Link href={`/product/${product?.slug}`}> */}
      <div className="product-description-section">
        <a>
          <h2 onClick={() => handleOpenProduct(product)}>
            {product?.title?.length > 25 ? product?.title.substring(0, 25) + '...' : product?.title}
          </h2>
        </a>
        <div className="price-analytics-section">
          {/* <div className="price-data">
            <h5>
              {product?.dis_price && '$'} {product?.dis_price}
            </h5>
            <span>
              {product?.dis_listPrice && '$'} {product?.dis_listPrice}
            </span>
          </div> */}
          <div className="price-data">
            <h5>
              <strong>{product?.dis_price && '$'}</strong> {product?.dis_price}
            </h5>
            <div className="price-analytics-section-price-and-buttons">
              <span>
                {product?.dis_listPrice && '$'} {product?.dis_listPrice}
              </span>
              <div className="price-analytics-section-buttons-main">
                <div className="price-analytics-section-buttons " onClick={() => !isBookmark && addBookmarkFromFeed(product)} >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill={isBookmark ? '#556EE6' : 'none'}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z"
                      stroke="#556EE6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="price-analytics-section-buttons" onClick={() => QuantityHandler()}>
                  <img src={BlueShoppingIcon.src} alt="BlueShoppingIcon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Link> */}
      {/* <div className="comment-section-alignment">
        <div className="like-share-alignment">
          <div className="like-alignment">
            <img src={TextMessageIcon.src} alt="TextMessageIcon" />
            <span>{product?.comment_count || 0}</span>
          </div>
          <div className="like-alignment">
            <img src={BlackheartIcon.src} alt="BlackheartIcon" />
            <span>{product?.like_count || 0}</span>
          </div>
          <div className="like-alignment">
            <img src={ShareIcon.src} alt="ShareIcon " />
            <span>{product?.sharepost_count || 0}</span>
          </div>
        </div>
      </div> */}

      {/* <------------------- mobile view remove product design start ----------------> */}
      {isRemoveProduct && (
        <div className="mobile-view-bookmark-remove-product-alignment">
          <div className="mobile-view-bookmark-remove-icon-alignment">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M19 12L5 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M12 19L5 12L12 5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M11.0753 3.47984L14.7908 3.01439C15.8168 2.88586 16.7504 3.63239 16.876 4.6818M5.46455 19.6118C4.43855 19.7403 3.50493 18.9938 3.37927 17.9444L3.03797 15.0942L2.69667 12.244L2.35537 9.39378L2.01407 6.54359C1.88841 5.49418 2.61828 4.53927 3.64429 4.41074"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect
                x="5.97852"
                y="5.26172"
                width="15.0447"
                height="15.0447"
                rx="1.395"
                transform="rotate(3.20951 5.97852 5.26172)"
                fill="white"
                stroke="white"
                strokeWidth="1.5"
              />
            </svg>
          </div>
        </div>
      )}
      {/* <------------------- mobile view remove product design end ----------------> */}

      {/* mobile view on summery section design start */}

      {/* mobile view on summery section design end */}
    </div>
  )
}
