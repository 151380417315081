import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useAuth } from 'src/utils/auth'
import JOIN_GROUP from 'src/graphql/mutations/joinGroup'
import GET_JOINED_GROUP from 'src/graphql/queries/sellerQueries/getJoinedGroups'
import { GET_GROUPS } from 'src/graphql/queries/queries'
import LazyImage from 'src/utils/LazyImage'
import Link from 'next/link'
import { fetchAllClubSliceData } from 'src/store/slices/fetchAllClubsSlice'

export default function NewSuggestedClubs() {
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const allClubPost = useSelector((state: any) => state?.clubData?.allPost)

  useEffect(() => {
    dispatch(fetchAllClubSliceData({ page: 1, limit: 2, type: 'SUGGESTED_CLUBS', prevPost: [] }))
  }, [])

  const [joingroup] = useMutation(JOIN_GROUP, {
    onCompleted: (res) => {},
    onError: (err) => {},
  })
  const joingroupCall = (club) => {
    if (user) {
      joingroup({
        variables: { group_id: club?.id, user_id: user?.id, isAdmin: false },
        update: (proxy, { data }) => {
          const suggestedGroup: any = proxy.readQuery({
            query: GET_JOINED_GROUP,
            variables: {
              page: 1,
              limit: 10,
              search: '',
            },
          })
          proxy.writeQuery({
            query: GET_JOINED_GROUP,
            data: {
              getJoinedGroups: [
                ...suggestedGroup?.getJoinedGroups,
                {
                  ...club,
                  isFollow: true,
                  members: [
                    {
                      firstName: user?.firstName,
                      lastName: user?.lastName,
                      id: user?.id,
                      profileUrl: user?.logo_image,
                    },
                    ...club?.members,
                  ],
                },
              ],
            },
          })
          const joinedGroup: any = proxy.readQuery({
            query: GET_GROUPS,
          })
          let updatedData = joinedGroup?.groups?.filter((grp) => grp?.id !== club?.id)
          proxy.writeQuery({
            query: GET_GROUPS,
            data: { groups: updatedData },
          })
        },
      })
      dispatch(fetchAllClubSliceData({}))
    } else {
      handleRedirect()
    }
  }

  const handleFollow = (club) => {
    if (user) {
      joingroupCall(club)
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handleClubOpen = (clubId) => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          { modal: 'group', isOpen: true, id: clubId, modalFor: 'bigModal' },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  const handelImageSlider = () => {}

  return Boolean(allClubPost?.length) ? (
    <div className="feed-new-suggested-clubs-alignment">
      <div className="feed-new-suggested-clubs-box">
        <div className="feed-new-suggested-clubs-heading">
          <h6>Suggested Clubs</h6>
          <a
            onClick={() => {
              dispatch(
                fetchModalData({
                  allModalData: [
                    ...getAllState?.allModalData,
                    { modal: 'clubModal', isOpen: true, id: 0, modalFor: 'bigModal' },
                  ],
                  lastModalData: getAllState?.lastModalData,
                }),
              )
            }}
          >
            See More
          </a>
        </div>
        <div className="feed-new-suggested-clubs-body-details-alignment">
          {allClubPost?.map(
            (group, i) =>
              i < 2 && (
                <div className="feed-new-suggested-details-box-alignment" key={i}>
                  <div className="feed-new-suggested-club-img">
                    {/* <img src={group?.bannerImage} alt="club img " /> */}
                    <LazyImage
                      image={{
                        src: group?.banner_image,
                        alt: 'Icon',
                      }}
                      objectFit={'cover'}
                      handleClickOnImage={() => handelImageSlider()}
                      className={'club-image-section'}
                    />
                  </div>
                  <div className="feed-new-suggested-club-details-alignment">
                    <div className="feed-new-suggested-heading">
                      <Link href={`/club/${group?.slug}`}>
                        <h6>{group?.name}</h6>
                      </Link>
                    </div>
                    <div className="feed-new-suggested-club-member-details">
                      <div className="feed-new-suggested-club-member-profile-alignment" onClick={handleRedirect}>
                        {group?.members.slice(0, 3)?.map((member, index) => {
                          return (
                            <div className="club-img-details" key={index}>
                              <img
                                src={
                                  member?.id === user?.id && user?.logo_image ? user?.logo_image : member?.logo_image
                                }
                                alt="club img "
                              />
                            </div>
                          )
                        })}
                      </div>
                      {group?.members?.length > 3 && group?.total_members - group?.members.slice(0, 3)?.length > 0 && (
                        <div className="total-club-member-box">
                          <p>
                            +
                            {group?.total_members - group?.members.slice(0, 3)?.length > 0
                              ? group?.total_members - group?.members.slice(0, 3)?.length
                              : ''}
                          </p>
                        </div>
                      )}
                    </div>
                    {group?.isExist ? (
                      <div className="feed-new-suggested-unfollow-details">
                        {/* <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="10"
                            viewBox="0 0 13 10"
                            fill="none"
                          >
                            <path
                              d="M1 4.5L5 8.5L12 1"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div> */}
                        <a>Joined</a>
                      </div>
                    ) : (
                      <div className="join-club-memeber-button-alignment" onClick={() => handleFollow(group)}>
                        <button>+ Join Club</button>
                      </div>
                    )}
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
