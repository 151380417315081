import Link from 'next/link'
import React from 'react'

export default function NewFooter() {
  const handleMove = (url) => {
    window.open(url)
  }

  return (
    <div className="new-footer-section">
      <div className="new-footer-top-details">
        <div className="new-footer-grid">
          <div
            className="new-footer-grid-item"
            // onClick={() => handleMove('https://seller.bluejestic.com/')}
          >
            <Link href="/marketplace">
              <p>Sell with Us</p>
            </Link>
          </div>
          <div className="new-footer-grid-item">
            <p>Help Center</p>
          </div>
          <div className="new-footer-grid-item">
            <Link href="/terms-of-use">
              <p>Term of Use</p>
            </Link>
          </div>
          <div className="new-footer-grid-item">
            <Link href="/privacy-policy">
              <p>Privacy Policy</p>
            </Link>
          </div>
          <div className="new-footer-grid-item">
            <Link href="/privacy-policy#cookie-policy">
              <p>Cookie Policy</p>
            </Link>
          </div>
          <div className="new-footer-grid-item" onClick={() => handleMove('https://about.bluejestic.com/')}>
            {/* <Link href="/about"> */}
            <p>About</p>
            {/* </Link> */}
          </div>
        </div>
      </div>

      <div className="new-footer-copy-right-section">
        <p>© 2024 Bluejestic. All rights reserved.</p>
      </div>
    </div>
  )
}
