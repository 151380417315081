// @ts-nocheck
import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import Product from 'src/components/ProductPage/AllProduct/Product'
import Productv2 from 'src/components/ProductPage/AllProduct/Productv2'
import REMOVE_BOOKMARK from 'src/graphql/mutations/removeBookmark'
import { fetchAllBookmarkProducts } from 'src/store/slices/fetchAllStoresSlice'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { manageBookmark } from 'src/store/slices/manageBookmarkTab'
import { fetchProductByCategory } from 'src/store/slices/productsDataSlice'
import { useAppDispatch } from 'src/store/store'
import { useAuth } from 'src/utils/auth'

export default function ProductRecommendation({ productRecommendations }) {
  const bookmarkTabData = useSelector((state: any) => state?.manageBookmarkTabsSlice?.data)
  const dispatch = useAppDispatch()
  const { user } = useAuth()

  function SampleNextArrow(props) {
    const { onClick } = props
    return (
      <div className="slick-next-arrow" onClick={onClick}>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.6" cx="20" cy="20" r="20" transform="rotate(-180 20 20)" fill="white" />
          <path
            d="M21 25L17 20L21 15"
            stroke="#1E2432"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { onClick } = props
    return (
      <div className="slick-prev-arrow" onClick={onClick}>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.6" cx="20" cy="20" r="20" transform="rotate(-180 20 20)" fill="white" />
          <path
            d="M21 25L17 20L21 15"
            stroke="#1E2432"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    )
  }

  var ProductRecommendations = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    //   rtl: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  }

  // const [removeBookmark] = useMutation(REMOVE_BOOKMARK, {
  //   onCompleted: (res) => {
  //     console.log('ressssss', res)
  //   },
  //   onError: (error) => {
  //     console.log(error)
  //   },
  // })

  // const handleRedirect = () => {
  //   dispatch(
  //     guestAccountDetailSlice({
  //       modal: 'authModal',
  //       isOpen: true,
  //       id: {
  //         media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
  //         modalFor: 'login',
  //         startWith: 'loginModal',
  //       },
  //     }),
  //   )
  // }

  // const addBookmark = (product) => {
  //   console.log('productttttttttt', product)
  //   if (user) {
  //     if (product?.bookmark?.id) {
  //       removeBookmark({
  //         variables: {
  //           id: product?.bookmark?.id,
  //         },
  //       })
  //     } else {
  //       dispatch(
  //         manageBookmark({
  //           modal: '',
  //           isOpen: true,
  //           id: {
  //             ...bookmarkTabData?.id,
  //             // post: post,
  //             type: 'feed',
  //             bookmark_id: product?.bookmark?.id,
  //             product_id: product?.id,
  //             collection_id: product?.bookmark?.collection?.id,
  //             addProductToCollection: {
  //               ...bookmarkTabData?.id?.deleteCollection,
  //               isShowAddCollectionModel: true,
  //               productId: product?.id,
  //             },
  //           },
  //         }),
  //       )
  //     }
  //   } else {
  //     handleRedirect()
  //   }
  // }

  return (
    <>
      <div className="rounded-xl mobile:border-t mobile:border-gray-300 custom-mb-8 feed-post-details-box p-4 recommendation-section-main-box">
        <h1>Products Recommendations</h1>
        {/* <Product product={productRecommendations} showProduct={{}} storeData={{}} isForPost={true} /> */}
        <div className="recommendation-section-main">
          <div className="recommendation-section">
            <Slider {...ProductRecommendations}>
              {productRecommendations?.map((recommendation, index) => (
                // <Product
                //   product={recommendation}
                //   showProduct={{}}
                //   storeData={{}}
                //   isForPost={true}
                //   isRecommendation={true}
                //   // bookMark={addBookmark}
                // />
                <Productv2
                  product={recommendation}
                  showProduct={{}}
                  storeData={{}}
                  isForPost={true}
                  isRecommendation={true}
                  // bookMark={addBookmark}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  )
}
