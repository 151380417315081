import React, { useEffect, useState } from 'react'
import styles from './SendInvitation.module.scss'
import SendInvitationEmail from '../SendinvitationbyEmail'
import { useGoogleLogin } from '@react-oauth/google'
import { TwitterShareButton } from 'react-share'
import { notification } from 'antd'

const SCOPES = 'https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/userinfo.email'
export default function SendInvitation({ setIsModalVisible }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [contacts, setContacts] = useState([])

  // Facbook post share Feature
  useEffect(() => {
    // Load Facebook SDK
    ;(function (d: Document, s: string, id: string) {
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      const js = d.createElement(s) as HTMLScriptElement // Cast to HTMLScriptElement
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode?.insertBefore(js, fjs) // Added optional chaining for safety
    })(document, 'script', 'facebook-jssdk')

    // Initialize Facebook SDK after it loads
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '346654831753825', // Replace with your Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v12.0',
      })
      window.FB.AppEvents.logPageView()
    }
  }, [])

  const shareOnFacebook = () => {
    // Ensure FB is initialized
    if (window.FB) {
      window.FB.ui(
        {
          method: 'share',
          href: 'https://bluejestic.com/', // Replace with the URL you want to share
          picture: 'https://bluejestic.com/assets/img/favicons/icon.png',
        },
        function (response) {},
      )
    } else {
    }
  }

  // Gmail Login Feature
  const openModal = () => {
    login()
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const login = useGoogleLogin({
    scope: SCOPES,
    onSuccess: async (response) => {
      const accessToken = response.access_token
      fetchContacts(accessToken)
    },
    onError: (error) => {},
  })

  const fetchContacts = async (accessToken: string) => {
    try {
      const res = await fetch(
        `https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses,phoneNumbers&access_token=${accessToken}`,
      )
      const data = await res.json()
      setContacts(data?.connections || [])
    } catch (error) {}
  }

  type Contact = {
    emailAddresses?: { value: string }[]
    names?: { displayName: string }[]
    phoneNumbers?: { canonicalForm: string }[]
  }

  const transformedContacts = contacts
    .map((item: Contact) => {
      const newItem: Contact = {}

      if (item.emailAddresses?.length > 0) {
        newItem.emailAddresses = item.emailAddresses.map((email) => ({
          value: email.value,
        }))
      }

      if (item.names?.length > 0) {
        newItem.names = item.names.map((name) => ({
          displayName: name.displayName,
        }))
      }

      if (item.phoneNumbers?.length > 0) {
        newItem.phoneNumbers = item.phoneNumbers.map((phone) => ({
          canonicalForm: phone.canonicalForm,
        }))
      }

      return newItem
    })
    .filter((item: Contact) => item.emailAddresses !== undefined)

  // Whatsapp Login Feature

  const projectLink = 'https://bluejestic.com/'
  const message = `I want you to join me on MeWe, the Next-Gen Social Network. No Ads. No Spyware. No BS: ${projectLink}`

  const openWhatsAppWeb = () => {
    const whatsappUrl = `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`
    const mobileWhatsAppUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`
    const isWebAvailable = navigator.userAgent.includes('WebKit')

    if (isWebAvailable) {
      window.open(whatsappUrl, '_blank')
    } else {
      window.open(mobileWhatsAppUrl, '_blank')
    }
  }

  // Pinterest Login Feature

  const url = 'https://bluejestic.com/'
  const image = `${window?.location?.origin}/assets/img/pinterestmobile-logo-new.svg`
  const description = 'Pinterest'
  const shareToPinterest = () => {
    const pinterestUrl = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
      url,
    )}&media=${encodeURIComponent(image)}&description=${encodeURIComponent(description)}`
    window.open(
      pinterestUrl,
      'Pinterest Share',
      'width=600,height=600,top=100,left=100,toolbar=no,menubar=no,scrollbars=yes,resizable=yes',
    )
  }

  // Share Code

  const handleShare = (type) => {}

  // Copy Link

  const handleCopyClick = (text) => {
    navigator?.clipboard
      .writeText(text)
      .then(() => {
        notification['success']({
          message: 'Copied!',
        })
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err)
      })
  }

  return (
    <>
      <div className={styles.sendInvitationModalWrapper}>
        <div className={styles.sendInvitationModalBox}>
          <div className={styles.invitationModalHeding}>
            <h4>Invite Friends to Bluejestic</h4>

            <div className={styles.headingCLose} onClick={() => setIsModalVisible(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 6L18 18" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>

          <div className={styles.sendInvitationModalBody}>
            <p>Share your profile link to invite friend to Bluejestic</p>
            <div className={styles.profileLinkInput}>
              <label>Profile Link</label>
              <div className={styles.profileInput}>
                <input type="text" placeholder="http://www.bluejestic.com/profile/marcos" />

                <div className={styles.copyIcon}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                      stroke="#7A7E88"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                      stroke="#7A7E88"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className={styles.socialShareOptionDetails}>
              <h6>Share via social networks</h6>

              <div className={styles.socialShareOption}>
                <div className={styles.optionBox} onClick={shareOnFacebook}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                    <path
                      d="M33.0001 17.9981C33.0001 9.71386 26.2843 2.99805 18.0001 2.99805C9.71581 2.99805 3 9.71374 3 17.9981C3 25.485 8.48534 31.6907 15.6563 32.816V22.3341H11.8477V17.9981H15.6563V14.6934C15.6563 10.934 17.8958 8.85745 21.322 8.85745C22.9632 8.85745 24.6798 9.15042 24.6798 9.15042V12.8418H22.7884C20.9248 12.8418 20.3438 13.9981 20.3438 15.1844V17.9981H24.504L23.839 22.3341H20.3438V32.816C27.5148 31.6907 33.0001 25.4851 33.0001 17.9981Z"
                      fill="#1877F2"
                    />
                    <path
                      d="M23.8389 22.334L24.504 17.9981H20.3438V15.1844C20.3438 13.998 20.9249 12.8418 22.7883 12.8418H24.6797V9.15039C24.6797 9.15039 22.9632 8.85742 21.322 8.85742C17.8957 8.85742 15.6563 10.934 15.6563 14.6934V17.9981H11.8477V22.334H15.6563V32.8159C16.4316 32.9374 17.2152 32.9984 18 32.9981C18.7848 32.9984 19.5685 32.9375 20.3438 32.8159V22.334H23.8389Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className={styles.optionBox} onClick={openWhatsAppWeb}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                    <path
                      d="M28.5753 7.36365C27.2001 5.97453 25.5621 4.87314 23.7568 4.12371C21.9514 3.37428 20.015 2.99181 18.0603 2.99862C9.87023 2.99862 3.1952 9.67366 3.1952 17.8637C3.1952 20.4887 3.8852 23.0387 5.17521 25.2888L3.0752 32.9988L10.9502 30.9288C13.1253 32.1138 15.5703 32.7438 18.0603 32.7438C26.2503 32.7438 32.9254 26.0688 32.9254 17.8787C32.9254 13.9037 31.3804 10.1687 28.5753 7.36365ZM18.0603 30.2238C15.8403 30.2238 13.6653 29.6238 11.7602 28.4988L11.3102 28.2288L6.63022 29.4588L7.87522 24.8988L7.57522 24.4338C6.34183 22.4642 5.68692 20.1876 5.68521 17.8637C5.68521 11.0537 11.2352 5.50364 18.0453 5.50364C21.3453 5.50364 24.4503 6.79365 26.7753 9.13366C27.9266 10.2796 28.8389 11.6427 29.4594 13.1438C30.08 14.645 30.3964 16.2544 30.3904 17.8787C30.4204 24.6887 24.8703 30.2238 18.0603 30.2238ZM24.8403 20.9837C24.4653 20.8037 22.6353 19.9037 22.3053 19.7687C21.9603 19.6487 21.7203 19.5887 21.4653 19.9487C21.2103 20.3237 20.5053 21.1637 20.2953 21.4037C20.0853 21.6587 19.8603 21.6887 19.4853 21.4937C19.1103 21.3137 17.9103 20.9087 16.5003 19.6487C15.3903 18.6587 14.6553 17.4437 14.4303 17.0687C14.2203 16.6937 14.4003 16.4987 14.5953 16.3037C14.7603 16.1387 14.9703 15.8687 15.1503 15.6587C15.3303 15.4487 15.4053 15.2837 15.5253 15.0437C15.6453 14.7887 15.5853 14.5787 15.4953 14.3987C15.4053 14.2187 14.6553 12.3887 14.3553 11.6387C14.0553 10.9187 13.7403 11.0087 13.5153 10.9937H12.7953C12.5402 10.9937 12.1502 11.0837 11.8052 11.4587C11.4752 11.8337 10.5152 12.7337 10.5152 14.5637C10.5152 16.3937 11.8502 18.1637 12.0302 18.4037C12.2102 18.6587 14.6553 22.4087 18.3753 24.0137C19.2603 24.4037 19.9503 24.6287 20.4903 24.7938C21.3753 25.0788 22.1853 25.0338 22.8303 24.9438C23.5503 24.8387 25.0353 24.0437 25.3353 23.1737C25.6503 22.3037 25.6503 21.5687 25.5453 21.4037C25.4403 21.2387 25.2153 21.1637 24.8403 20.9837Z"
                      fill="#65D072"
                    />
                  </svg>
                </div>
                <div className={styles.optionBox} onClick={shareToPinterest}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                    <path
                      d="M13.5601 32.3087C15.0001 32.7437 16.4551 32.9987 18.0001 32.9987C21.9784 32.9987 25.7937 31.4184 28.6068 28.6053C31.4198 25.7922 33.0002 21.9769 33.0002 17.9986C33.0002 16.0288 32.6122 14.0782 31.8584 12.2583C31.1045 10.4384 29.9996 8.78485 28.6068 7.39196C27.2139 5.99907 25.5603 4.89417 23.7404 4.14035C21.9205 3.38652 19.9699 2.99854 18.0001 2.99854C16.0302 2.99854 14.0797 3.38652 12.2598 4.14035C10.4399 4.89417 8.78631 5.99907 7.39342 7.39196C4.58036 10.205 3 14.0204 3 17.9986C3 24.3737 7.00502 29.8487 12.6601 32.0087C12.5251 30.8387 12.3901 28.9037 12.6601 27.5687L14.3851 20.1586C14.3851 20.1586 13.9501 19.2886 13.9501 17.9086C13.9501 15.8386 15.2401 14.2936 16.7101 14.2936C18.0001 14.2936 18.6001 15.2386 18.6001 16.4536C18.6001 17.7436 17.7451 19.5886 17.3101 21.3586C17.0551 22.8287 18.0901 24.1187 19.5901 24.1187C22.2601 24.1187 24.3301 21.2686 24.3301 17.2486C24.3301 13.6486 21.7501 11.1886 18.0451 11.1886C13.8151 11.1886 11.325 14.3386 11.325 17.6536C11.325 18.9436 11.7451 20.2486 12.4351 21.1036C12.5701 21.1936 12.5701 21.3136 12.5251 21.5386L12.0901 23.1737C12.0901 23.4287 11.9251 23.5187 11.6701 23.3387C9.75004 22.4986 8.64003 19.7686 8.64003 17.5636C8.64003 12.8236 12.0001 8.51857 18.4801 8.51857C23.6401 8.51857 27.6601 12.2236 27.6601 17.1436C27.6601 22.3036 24.4651 26.4437 19.8901 26.4437C18.4351 26.4437 17.0101 25.6637 16.5001 24.7487L15.4951 28.3037C15.1501 29.5937 14.2051 31.3187 13.5601 32.3537V32.3087Z"
                      fill="#D32D2F"
                    />
                  </svg>
                </div>
                <div className={styles.optionBox} onClick={() => handleShare('twitter')}>
                  <TwitterShareButton url={`${process.env.NEXT_PUBLIC_DOMAIN}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path
                        d="M21.901 4.50049H25.581L17.541 13.3969L27 25.5005H19.594L13.794 18.1588L7.156 25.5005H3.474L12.074 15.9845L3 4.50146H10.594L15.837 11.212L21.901 4.50049ZM20.61 23.3688H22.649L9.486 6.52082H7.298L20.61 23.3688Z"
                        fill="#101419"
                      />
                    </svg>
                  </TwitterShareButton>
                </div>
                {/* <div className={styles.optionBox}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_2077_3474)">
                      <path d="M9 0H0V9H9V0ZM7.5 7.5H1.5V1.5H7.5V7.5Z" fill="black" />
                      <path d="M3 3H6V6H3V3ZM0 24H9V15H0V24ZM1.5 16.5H7.5V22.5H1.5V16.5Z" fill="black" />
                      <path d="M3 18H6V21H3V18ZM15 0V9H24V0H15ZM22.5 7.5H16.5V1.5H22.5V7.5Z" fill="black" />
                      <path
                        d="M18 3H21V6H18V3ZM3 10.5H0V13.5H4.5V12H3V10.5ZM10.5 13.5H13.5V16.5H10.5V13.5ZM4.5 10.5H7.5V12H4.5V10.5ZM13.5 18H10.5V19.5H12V21H13.5V19.5V18ZM9 10.5V12H7.5V13.5H10.5V10.5H9ZM12 6H13.5V9H12V6ZM13.5 12V13.5H16.5V10.5H12V12H13.5ZM10.5 9H12V10.5H10.5V9ZM13.5 21H16.5V24H13.5V21ZM10.5 21H12V24H10.5V21ZM13.5 16.5H15V18H13.5V16.5ZM13.5 4.5V1.5H12V0H10.5V6H12V4.5H13.5ZM18 21H19.5V24H18V21ZM18 18H21V19.5H18V18ZM16.5 19.5H18V21H16.5V19.5ZM15 18H16.5V19.5H15V18ZM21 15V16.5H22.5V18H24V15H22.5H21ZM22.5 19.5H21V24H24V21H22.5V19.5ZM15 15V16.5H19.5V13.5H16.5V15H15ZM18 10.5V12H21V13.5H24V10.5H21H18Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2077_3474">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div> */}
                <div className={styles.optionBox} onClick={() => handleCopyClick(`${process.env.NEXT_PUBLIC_DOMAIN}`)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M10 13.0028C10.4294 13.577 10.9774 14.052 11.6065 14.3958C12.2357 14.7395 12.9315 14.9439 13.6466 14.9952C14.3618 15.0464 15.0796 14.9432 15.7513 14.6926C16.4231 14.442 17.0331 14.0499 17.54 13.5428L20.5399 10.5428C21.4507 9.59984 21.9547 8.33684 21.9433 7.02586C21.9319 5.71488 21.4061 4.46083 20.479 3.53379C19.552 2.60676 18.2979 2.08092 16.987 2.06952C15.676 2.05813 14.413 2.5621 13.47 3.47289L11.75 5.18288"
                      stroke="#1E2432"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0001 11.0022C13.5707 10.428 13.0228 9.95299 12.3936 9.60923C11.7644 9.26548 11.0686 9.06106 10.3535 9.00984C9.63836 8.95863 8.92057 9.06181 8.24882 9.31239C7.57706 9.56298 6.96706 9.9551 6.46017 10.4622L3.46019 13.4622C2.54941 14.4052 2.04544 15.6682 2.05683 16.9791C2.06822 18.2901 2.59406 19.5442 3.5211 20.4712C4.44813 21.3982 5.70219 21.9241 7.01316 21.9355C8.32414 21.9469 9.58714 21.4429 10.5301 20.5321L12.2401 18.8221"
                      stroke="#1E2432"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className={styles.socialShareOptionDetails}>
              <h6>Upload your address book to invite friends</h6>

              <div className={styles.socialShareOption}>
                <div className={styles.optionBox} onClick={openModal}>
                  <img src="/assets/icon/gmail-icon.svg" alt="gmail icon" />
                </div>
                {/* <div className={styles.optionBox}>
                  <img src="/assets/icon/look-icon.svg" alt="look icon" />
                </div>
                <div className={styles.optionBox}>
                  <img src="/assets/icon/csv-icon.svg" alt="csv icon" />
                </div>
                <div className={styles.optionBox}>
                  <img src="/assets/icon/yahoo-icon.svg" alt="yahoo icon" />
                </div> */}
              </div>
            </div>

            <div className={styles.inviteFriendAlignment}>
              <div className={styles.inviteFriendLabel}>
                <label>Invite Friend by E-mail</label>
              </div>
              <div className={styles.inviteFriendInput}>
                <div className={styles.inviteFriendGrid}>
                  <div className={styles.inviteFriendEmailInput}>
                    <input type="text" placeholder="Enter address" />
                  </div>

                  <div className={styles.inviteButton}>
                    <button>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M12 5V19"
                          stroke="#556EE6"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 12H19"
                          stroke="#556EE6"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inviteFriendSendButton}>
              <button>Send</button>
            </div>
          </div>
        </div>
      </div>

      <SendInvitationEmail
        isOpen={isModalOpen}
        onClose={closeModal}
        transformedContacts={transformedContacts}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  )
}
