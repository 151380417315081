// @ts-nocheck

import React, { useState } from 'react'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import { Modal, Tabs, Checkbox } from 'antd'
import { useAuth } from 'src/utils/auth'
const { TabPane } = Tabs
const social_facebook = <img src="/assets/img/left-sidebar/fb.svg" />
const social_twitter = <img src="/assets/img/left-sidebar/twitter.svg" />
const social_instagram = <img src="/assets/img/left-sidebar/instagram.svg" />
const social_linkedin = <img src="/assets/img/left-sidebar/linkedin.svg" />
const social_tiktok = <img src="/assets/img/left-sidebar/tiktok.svg" />

export default function InviteYourFriends({ setIsModalVisible }) {
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()

  const handleRedirect = () => {
    if (user) {
      setIsModalVisible(true)
    } else {
      dispatch(
        guestAccountDetailSlice({
          modal: 'authModal',
          isOpen: true,
          id: {
            media:
              'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
            modalFor: 'login',
            startWith: 'loginModal',
          },
        }),
      )
    }
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  function onChange(e: { target: { checked: any } }) {}

  return (
    <>
      <div className="invite-your-friend-details">
        <div className="invite-your-friend-heading">
          <h4>Invite your Friends!</h4>
        </div>
        <div className="invite-your-friend-profile-alignment" onClick={handleRedirect}>
          <div className="invite-your-friend-details-profile">
            <img src="/assets/img/feed-demo-1.png" alt="feed profile" />
          </div>
          <div className="invite-your-friend-details-profile">
            <img src="/assets/img/feed-demo-2.png" alt="feed profile" />
          </div>
          <div className="invite-your-friend-details-profile">
            <img src="/assets/img/feed-demo-3.png" alt="feed profile" />
          </div>
          <div className="invite-your-friend-details-profile">
            <img src="/assets/img/feed-demo-4.png" alt="feed profile" />
          </div>
        </div>
        <div className="invite-your-friend-notes">
          <p>Friends who shop together, save together! Invite your buddies and let's snag deals as a team."</p>
        </div>

        <div className="invitation-send-button" onClick={handleRedirect}>
          <button>Send Invitation</button>
        </div>
      </div>
    </>
  )
}
